.ResumeContain {
    max-height: 805px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.pdfIcon, .articleIcon {
    color:#3f4c6b;

}

.pdfIcon:hover {
    color: #606c88;
}

.articleIcon:hover {
    color: #606c88;
}
button {
    background: none;
    border: none;
    text-align: left;
}
