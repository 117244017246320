.container-fluid {
    padding: 0px;
    margin: 0px;
}

.Card {
    -webkit-box-shadow: 4px -3px 38px -15px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px -3px 38px -15px rgba(0,0,0,0.75);
    box-shadow: 4px -3px 38px -15px rgba(0,0,0,0.75);
}

.btn {
    background: linear-gradient(to bottom, #606c88 0%,#3f4c6b 100%);
}

.btn:hover {
    background-color:#606c88;
}


/* X-Small devices (portrait phones, less than 576px) 
No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {  }

/* X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) { 

}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 
    .mainContainer {
        margin-left: 270px;
        margin-top: 25px!important;
        padding: 10px;
    }
}

@media (min-width: 1920px) { 
    .mainContainer {
        margin-top: 6px!important;
    }
}

/* Custom Media Queries */
@media (min-width: 320px) { 
    .mainContainer {
        margin-top: 85px;
        padding: 10px;
        margin-right: 10px;
    }

    .jumbotron {
        background-image: url("../../public/images/banner.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        border: 1px solid rgb(228, 228, 228);
        border-radius: 15px;
        -webkit-box-shadow: 4px -3px 38px -15px rgba(0,0,0,0.75);
        -moz-box-shadow: 4px -3px 38px -15px rgba(0,0,0,0.75);
        box-shadow: 4px -3px 38px -15px rgba(0,0,0,0.75);
        color: #fff;
        text-shadow: 2px 2px 2px rgba(0,0,0,0.26);
    }

    .jumbotron h1 {
        text-shadow: 2px 2px 2px rgba(0,0,0,0.26);
    }

    .overlay {
        background-color: rgba(0,0,0,0.45);
        padding: 90px 50px;
        border-radius: 15px;
    }
}