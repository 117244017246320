body {
  margin: 0;
  color: #444;
}

h1, h2, h3 {
  font-family: 'Staatliches', cursive;
  color: #3f4c6b;
}

code {
  font-family: 'Staatliches', cursive;
}
