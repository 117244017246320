.sidebar {
    position: absolute;
    width: 100%;
    height: 77px;
    top: 0;
    left: 0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#606c88+0,3f4c6b+100;Grey+3D+%232 */
    background: #606c88; /* Old browsers */
    background: -moz-linear-gradient(top,  #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #606c88 0%,#3f4c6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    border-bottom: 4px solid #FF5910;
    border-right: 0px;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    -webkit-box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.2);
}

.sidebar .container {
    padding: 0px;
}

.sidebar .container a {
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.sidebar img {
    position: relative;
    max-width: 15.5%!important;
    display: flex;
    left: 8px;
}

.name {
    position: relative;
    top: -5px;
    left: 11px;
}

.titles {
    display: none;
}

.social {
    display: none!important;
}

.navbar-toggler {
    color: white;
}

.navbar-light .navbar-toggler {
    border: none;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0;
}

.navbar-collapse {
    position: relative;
    margin-top: 11px;
}

.bg-sidebar {
    background: #606c88; /* Old browsers */
    background: -moz-linear-gradient(top,  #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #606c88 0%,#3f4c6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    border-bottom: 4px solid #FF5910;
    padding: 15px;
}

.menu li {
    margin-bottom: 5px;
}

.navbar-light, .navbar-nav, .nav-link, .nav-link.active {
    color: #fff!important;
}

.test:hover {
  color: #adadad!important;
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 425px) { 
    .sidebar img {
        position: relative;
        max-width: 13.5%!important;
        display: flex;
        left: 8px;
    }
}



@media (min-width: 576px) { 

 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .sidebar img {
        position: relative;
        max-width: 8%!important;
        display: flex;
        left: 8px;
    }
    .navbar-collapse {
        position: relative;
        top: 0px;
    }
    .sidebar .container {
        padding: 0px;
    }

    .bg-sidebar {
        background: #606c88; /* Old browsers */
        background: -moz-linear-gradient(top,  #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #606c88 0%,#3f4c6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        border-bottom: 4px solid #FF5910;
        padding: 15px;
    }
    .mainContainer {
        margin-top: 82px;
        padding: 10px;
        margin-left: 0px;
    }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {  
    .sidebar img {
        position: relative;
        max-width: 6%!important;
        display: flex;
        left: 8px;
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
        background: none;
        border-bottom: none;
        margin-top: 13px;
    }
    .sidebar .container a {
        color: #fff;
        font-weight: bold;
        margin-left: 24px!important;
    }
    .navbar-brand {
        margin-top: 0px;
        margin-left: 25px!important;
    }
}

@media (min-width: 1025px) {
    .sidebar img {
        position: absolute;
        max-width: 50%!important;
        top: 8px;
        margin: 0 auto;
        left: 58px;
    }
    .sidebar .container a {
        color: #fff;
        font-weight: bold;
        margin-left: 60px!important;
    }

    .navbar>.container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
        background: none;
        border-bottom: none;
        margin-left: -37px;
        margin-top: -61px;
    }
    .titles {
        display: block;
    }

    .titlePoints {
        position: relative;
        top: -85px;
        left: -27px;
    }
    .navbar-collapse {
        position: relative;
        margin-top: -70px;
    }
    .logo {
        position: absolute;
        top: 150px;
        left: 0;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .sidebar {
        position: absolute;
        width: 260px!important;
        height: calc(100% + 0px)!important;
        top: 0;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#606c88+0,3f4c6b+100;Grey+3D+%232 */
        background: #606c88; /* Old browsers */
        background: -moz-linear-gradient(top,  #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #606c88 0%,#3f4c6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        border-right: 4px solid #FF5910!important;
        border-bottom: 0px!important;
        box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.2);
        -webkit-box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.2);
        -moz-box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.2);
    }

    .social {
        margin-top: 286px;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: column;
        background: none;
        border-bottom: none;
    }

    .mainContainer {
        margin-top: 25px;
        padding: 10px;
        margin-left: 265px;
    }
    
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 
    .sidebar {
        height: calc(100% + 0px)!important;
    }
    .social {
        margin-top: 234px;
    }
}

@media (min-width: 1920px) { 


}

/* Custom Media Queries */


